import { BiCheck } from "react-icons/bi";
import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiHorizontalRule,
  VuiIcon,
  VuiSpacer,
  VuiText,
  VuiTextColor,
  VuiTitle,
} from "../../../ui";
// import { createSearchParams } from "../../../utils/createSearchParams";

type Props = {
  query?: string;
  results?: number;
  commentsWeight?: number;
  votes?: number;
  comments?: number;
  sub?: string;
  isDiversity?: boolean;
  date: string;
};

export const HistoryDrawerItem = ({
  query,
  results,
  commentsWeight,
  votes,
  comments,
  sub,
  isDiversity,
  date,
}: Props) => {
  // const searchParams = createSearchParams({
  //   searchValue: query,
  //   numResults: results,
  //   votes,
  //   comments,
  //   targetedSub: sub,
  //   isDiversityReranking: isDiversity,
  //   commentsWeight,
  // });

  return (
    <>
      <VuiFlexContainer alignItems="end" justifyContent="spaceBetween">
        <VuiFlexItem grow={1}>
          <VuiTitle size="xs">
            <h4>
              {/*<VuiLink href={`/${searchParams}`}>{query}</VuiLink>*/}
              {query}
            </h4>
          </VuiTitle>
        </VuiFlexItem>

        <VuiFlexItem grow={false}>
          <VuiText size="xs">
            <p>
              <VuiTextColor color="subdued">{date}</VuiTextColor>
            </p>
          </VuiText>
        </VuiFlexItem>
      </VuiFlexContainer>

      <VuiSpacer size="xs" />
      <VuiHorizontalRule />
      <VuiSpacer size="xxs" />

      <KeyValue label="Results" value={results} />
      <KeyValue label="Comments weight" value={commentsWeight} />
      <KeyValue label="Min votes" value={votes} />
      <KeyValue label="Min comments" value={comments} />
      <KeyValue label="Target sub" value={sub} />
      <KeyValue
        label="Diversify results"
        value={
          isDiversity && (
            <VuiIcon size="s">
              <BiCheck />
            </VuiIcon>
          )
        }
      />

      <VuiSpacer size="s" />
    </>
  );
};

const KeyValue = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => (
  <>
    <VuiFlexContainer>
      <VuiFlexItem grow={1}>
        <VuiText>
          <p>{label}</p>
        </VuiText>
      </VuiFlexItem>

      <VuiFlexItem grow={1}>
        <VuiText>
          <p>
            <VuiTextColor color="subdued">{value}</VuiTextColor>
          </p>
        </VuiText>
      </VuiFlexItem>
    </VuiFlexContainer>

    <VuiSpacer size="xxs" />
  </>
);
