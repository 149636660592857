/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  DeserializedSearchResult,
  SearchResponse,
  SummaryLanguage,
  SearchError,
} from "../views/search/types";
import { useConfigContext } from "./ConfigurationContext";
import { sendSearchRequest } from "./sendSearchRequest";
import {
  HistoryItem,
  addHistoryItem,
  deleteHistory,
  retrieveHistory,
} from "./history";
import { deserializeSearchResponse } from "../utils/deserializeSearchResponse";
import { useAuthenticationContext } from "./AuthenticationContext";
import { createSearchParams } from "../utils/createSearchParams";

interface SearchContextType {
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSearch: ({
    value,
    language,
    votes,
    comments,
    commentsWeightValue,
    sub,
    results,
    isDiversity,
    isPersistable,
  }: {
    value?: string;
    language?: SummaryLanguage;
    votes?: number;
    comments?: number;
    commentsWeightValue?: number;
    sub?: string;
    results?: number;
    isDiversity?: boolean;
    isPersistable?: boolean;
  }) => void;
  reset: () => void;
  isSearching: boolean;
  searchError: SearchError | undefined;
  searchResults: DeserializedSearchResult[] | undefined;
  searchTime: number;
  isSummarizing: boolean;
  summarizationResponse: SearchResponse | undefined;
  language: SummaryLanguage;
  summaryNumResults: number;
  summaryNumSentences: number;
  summaryPromptName: string;
  history: HistoryItem[];
  clearHistory: () => void;
  searchResultsRef: React.MutableRefObject<HTMLElement[] | null[]>;
  selectedSearchResultPosition: number | undefined;
  selectSearchResultAt: (position: number) => void;
  votes?: number;
  setVotes: (votes?: number) => void;
  comments?: number;
  setComments: (comments?: number) => void;
  targetedSub: string | undefined;
  setTargetedSub: (sub: string) => void;
  numResults: number;
  setNumResults: (numResults: number) => void;
  isDiversityReranking: boolean;
  setIsDiversityReranking: (isDiversityReranking: boolean) => void;
  commentsWeight?: number;
  setCommentsWeight: (weight?: number) => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

const getQueryParam = (urlParams: URLSearchParams, key: string) => {
  const value = urlParams.get(key);
  if (value) return decodeURIComponent(value);
  return undefined;
};

type Props = {
  children: ReactNode;
};

let searchCount = 0;

export const SearchContextProvider = ({ children }: Props) => {
  const { user } = useAuthenticationContext();
  const { isConfigLoaded, search, summary } = useConfigContext();

  const [numVotes, setVotes] = useState<number>();
  const [numComments, setComments] = useState<number>();
  const [targetedSub, setTargetedSub] = useState<string>("");
  const [numResults, setNumResults] = useState<number>(10);
  const [isDiversityReranking, setIsDiversityReranking] =
    useState<boolean>(false);
  const [commentsWeight, setCommentsWeight] = useState<number>();

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  // Language
  const [languageValue, setLanguageValue] = useState<SummaryLanguage>();

  // History
  const [history, setHistory] = useState<HistoryItem[]>([]);

  // Basic search
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState<SearchError | undefined>();
  const [searchResponse, setSearchResponse] = useState<SearchResponse>();
  const [searchTime, setSearchTime] = useState<number>(0);

  // Summarization
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [summarizationResponse, setSummarizationResponse] =
    useState<SearchResponse>();

  // Citation selection
  const searchResultsRef = useRef<HTMLElement[] | null[]>([]);
  const [selectedSearchResultPosition, setSelectedSearchResultPosition] =
    useState<number>();

  useEffect(() => {
    setHistory(retrieveHistory());
  }, []);

  // Use the browser back and forward buttons to traverse history
  // of searches, and bookmark or share the URL.
  useEffect(() => {
    // Search params are updated as part of calling onSearch, so we don't
    // want to trigger another search when the search params change if that
    // search is already in progress.
    if (!isConfigLoaded) return;

    const urlParams = new URLSearchParams(searchParams);

    onSearch({
      // Set to an empty string to wipe out any existing search value.
      value: getQueryParam(urlParams, "query") ?? "",
      commentsWeightValue: getQueryParam(urlParams, "commentsWeight")
        ? parseFloat(getQueryParam(urlParams, "commentsWeight")!)
        : undefined,
      votes: getQueryParam(urlParams, "votes")
        ? parseInt(getQueryParam(urlParams, "votes")!, 10)
        : undefined,
      comments: getQueryParam(urlParams, "comments")
        ? parseInt(getQueryParam(urlParams, "comments")!, 10)
        : undefined,
      sub: getQueryParam(urlParams, "sub"),
      results: getQueryParam(urlParams, "results")
        ? parseInt(getQueryParam(urlParams, "results")!, 10)
        : undefined,
      isDiversity:
        getQueryParam(urlParams, "diversity") === "true" ? true : undefined,
      language: getQueryParam(urlParams, "language") as
        | SummaryLanguage
        | undefined,
      isPersistable: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigLoaded, searchParams]); // TODO: Add onSearch and fix infinite render loop

  const searchResults = deserializeSearchResponse(searchResponse);

  useEffect(() => {
    if (searchResults) {
      searchResultsRef.current = searchResultsRef.current.slice(
        0,
        searchResults.length
      );
    } else {
      searchResultsRef.current = [];
    }
  }, [searchResults]);

  const clearHistory = () => {
    setHistory([]);
    deleteHistory();
  };

  const selectSearchResultAt = (position: number) => {
    if (
      !searchResultsRef.current[position] ||
      selectedSearchResultPosition === position
    ) {
      // Reset selected position.
      setSelectedSearchResultPosition(undefined);
    } else {
      setSelectedSearchResultPosition(position);
      // Scroll to the selected search result.
      window.scrollTo({
        top: searchResultsRef.current[position]!.offsetTop - 78,
        behavior: "smooth",
      });
    }
  };

  const getLanguage = (): SummaryLanguage =>
    (languageValue ?? summary.defaultLanguage) as SummaryLanguage;

  const onSearch = async ({
    value = searchValue,
    language = getLanguage(),
    votes = numVotes,
    comments = numComments,
    commentsWeightValue = commentsWeight,
    sub = targetedSub,
    results = numResults,
    isDiversity = isDiversityReranking,
    isPersistable = true,
  }: {
    value?: string;
    language?: SummaryLanguage;
    votes?: number;
    comments?: number;
    commentsWeightValue?: number;
    sub?: string;
    results?: number;
    isDiversity?: boolean;
    isPersistable?: boolean;
  }) => {
    const searchId = ++searchCount;

    setSearchValue(value);
    setVotes(votes);
    setComments(comments);
    setCommentsWeight(commentsWeightValue);
    setTargetedSub(sub);
    setNumResults(results);
    setIsDiversityReranking(isDiversity);
    setLanguageValue(language);

    const votesFilter = votes !== undefined ? `doc.votesCount >= ${votes}` : "";

    const commentsFilter =
      comments !== undefined ? `doc.commentsCount >= ${comments}` : "";

    const subFilter = sub.trim() !== "" ? `doc.srName = '${sub}'` : "";

    const filter = [votesFilter, commentsFilter, subFilter]
      .filter((value) => value !== "")
      .join(" and ");

    if (value?.trim()) {
      // Save to history.
      setHistory(
        addHistoryItem(
          {
            query: value,
            results,
            commentsWeight: commentsWeightValue,
            votes,
            comments,
            sub,
            language,
            isDiversity: isDiversityReranking,
          },
          history
        )
      );

      // Persist to URL, only if the search executes. This way the prior
      // search that was persisted remains in the URL if the search doesn't execute.
      if (isPersistable) {
        setSearchParams(
          new URLSearchParams(
            createSearchParams({
              searchValue: value,
              numResults,
              votes,
              comments,
              targetedSub,
              isDiversityReranking,
              commentsWeight,
              language,
            })
          )
        );
      }

      // First call - only search results - should come back quicky while we wait for summarization
      setIsSearching(true);
      setIsSummarizing(true);
      setSelectedSearchResultPosition(undefined);

      let initialSearchResponse;

      try {
        const startTime = Date.now();

        initialSearchResponse = await sendSearchRequest({
          numResults,
          filter,
          query: value,
          commentsWeight: commentsWeightValue,
          customerId: search.customerId!,
          corpusId: search.corpusId!,
          endpoint: search.endpoint!,
          apiKey: search.apiKey!,
          isDiversityReranking: isDiversity,
          user,
        });
        const totalTime = Date.now() - startTime;

        // If we send multiple requests in rapid succession, we only want to
        // display the results of the most recent request.
        if (searchId === searchCount) {
          setIsSearching(false);
          setSearchTime(totalTime);
          setSearchResponse(initialSearchResponse);

          if (initialSearchResponse.response.length > 0) {
            setSearchError(undefined);
          } else {
            setSearchError({
              message: "There weren't any results for your search.",
            });
          }
        }
      } catch (error) {
        console.log("Search error", error);
        setIsSearching(false);
        setSearchError(error as SearchError);
        setSearchResponse(undefined);
      }
    } else {
      // Persist to URL.
      if (isPersistable) setSearchParams(new URLSearchParams(""));

      setSearchResponse(undefined);
      setSummarizationResponse(undefined);
      setIsSearching(false);
      setIsSummarizing(false);
    }
  };

  const reset = () => {
    // Specifically don't reset language because that's more of a
    // user preference.
    setNumResults(10);
    setCommentsWeight(undefined);
    setVotes(undefined);
    setComments(undefined);
    setTargetedSub("");
    setIsDiversityReranking(false);
    setSearchValue("");
    setSearchResponse(undefined);
  };

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        setSearchValue,
        onSearch,
        reset,
        isSearching,
        searchError,
        searchResults,
        searchTime,
        isSummarizing,
        summarizationResponse,
        language: getLanguage(),
        summaryNumResults: summary.summaryNumResults,
        summaryNumSentences: summary.summaryNumSentences,
        summaryPromptName: summary.summaryPromptName,
        history,
        clearHistory,
        searchResultsRef,
        selectedSearchResultPosition,
        selectSearchResultAt,
        votes: numVotes,
        setVotes,
        comments: numComments,
        setComments,
        targetedSub,
        setTargetedSub,
        numResults,
        setNumResults,
        isDiversityReranking,
        setIsDiversityReranking,
        commentsWeight,
        setCommentsWeight,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      "useSearchContext must be used within a SearchContextProvider"
    );
  }
  return context;
};
