import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { BiTimeFive, BiX } from "react-icons/bi";
import { useConfigContext } from "../../../contexts/ConfigurationContext";
import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiSearchInput,
  VuiSpacer,
  VuiTitle,
  VuiTextColor,
  VuiIcon,
  VuiButtonSecondary,
  VuiNumberInput,
  VuiIconButton,
  VuiTextInput,
  VuiFormGroup,
  VuiToggle,
  VuiButtonPrimary,
  VuiCheckbox,
  VuiText,
} from "../../../ui";
import { useSearchContext } from "../../../contexts/SearchContext";
import "./searchControls.scss";
import { HistoryDrawer } from "./HistoryDrawer";
import { OptionsDrawer } from "./OptionsDrawer";
import redditLogo from "./reddit-logo.webp";

type Props = {
  hasQuery: boolean;
};

export const SearchControls = ({ hasQuery }: Props) => {
  const {
    searchValue,
    setSearchValue,
    onSearch,
    reset,
    votes,
    setVotes,
    comments,
    setComments,
    targetedSub,
    setTargetedSub,
    numResults,
    setNumResults,
    isDiversityReranking,
    setIsDiversityReranking,
    commentsWeight,
    setCommentsWeight,
  } = useSearchContext();
  const { searchHeader } = useConfigContext();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isCommentsWeightEnabled, setIsCommentsWeightEnabled] = useState(false);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onSearchSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch({ value: searchValue });
  };

  useEffect(() => {
    if (commentsWeight !== undefined) {
      setIsCommentsWeightEnabled(true);
    }
  }, [commentsWeight]);

  return (
    <>
      <div className="searchControls">
        <VuiFlexContainer alignItems="center" justifyContent="spaceBetween">
          <VuiFlexItem grow={false}>
            <VuiFlexContainer alignItems="center" spacing="s">
              <VuiFlexItem>
                <img
                  src={redditLogo}
                  alt="Reddit logo"
                  className="redditLogo"
                />
              </VuiFlexItem>

              <VuiFlexItem grow={false}>
                <VuiTitle size="m">
                  <h2>
                    <strong>Reddit</strong>
                  </h2>
                </VuiTitle>
              </VuiFlexItem>
            </VuiFlexContainer>
          </VuiFlexItem>

          <VuiFlexItem grow={false}>
            <VuiFlexContainer alignItems="center" spacing="m">
              {searchHeader.description && (
                <VuiFlexItem grow={false}>
                  <VuiTitle size="xxs" align="right">
                    <VuiTextColor color="subdued">
                      <h2 style={{ whiteSpace: "pre-line" }}>
                        {searchHeader.description.replaceAll("\\n", "\n")}
                      </h2>
                    </VuiTextColor>
                  </VuiTitle>
                </VuiFlexItem>
              )}

              {/* <VuiFlexItem grow={false}>
                <VuiButtonSecondary
                  color="neutral"
                  size="s"
                  isSelected={isOptionsOpen}
                  onClick={() => setIsOptionsOpen(!isOptionsOpen)}
                  icon={
                    <VuiIcon size="m">
                      <BiSlider />
                    </VuiIcon>
                  }
                >
                  Options
                </VuiButtonSecondary>
              </VuiFlexItem> */}

              <VuiFlexItem grow={false}>
                <VuiButtonSecondary
                  color="neutral"
                  size="s"
                  isSelected={isHistoryOpen}
                  onClick={() => setIsHistoryOpen(!isHistoryOpen)}
                  icon={
                    <VuiIcon size="m">
                      <BiTimeFive />
                    </VuiIcon>
                  }
                >
                  History
                </VuiButtonSecondary>
              </VuiFlexItem>
            </VuiFlexContainer>
          </VuiFlexItem>
        </VuiFlexContainer>

        <VuiSpacer size="m" />

        <VuiFlexContainer alignItems="center" spacing="m">
          <VuiFlexItem grow={1}>
            <VuiSearchInput
              size="l"
              value={searchValue}
              onChange={onSearchChange}
              onSubmit={onSearchSubmit}
              placeholder={searchHeader.placeholder ?? ""}
              autoFocus
            />
          </VuiFlexItem>

          <VuiFlexItem grow={false}>
            <VuiButtonPrimary
              color="primary"
              onClick={() => onSearch({ value: searchValue })}
              size="l"
            >
              Search
            </VuiButtonPrimary>
          </VuiFlexItem>
        </VuiFlexContainer>

        <VuiSpacer size="m" />

        <VuiFlexContainer alignItems="center" justifyContent="spaceBetween">
          <VuiFlexItem grow={1}>
            <fieldset>
              <VuiFlexContainer alignItems="center" wrap={true} spacing="l">
                {/* Num results */}
                <VuiFlexItem grow={false}>
                  <VuiFormGroup label="Results" labelFor="resultsInput">
                    <VuiNumberInput
                      id="resultsInput"
                      min={1}
                      className="resultsInput"
                      value={numResults}
                      onChange={(value) => {
                        setNumResults(value ?? 1);
                      }}
                    />
                  </VuiFormGroup>
                </VuiFlexItem>

                {/* Comment weight */}
                <VuiFlexItem grow={false}>
                  <VuiFormGroup
                    label="Comments weight"
                    labelFor="commentWeightInput"
                  >
                    <VuiFlexContainer spacing="xs" alignItems="center">
                      <VuiFlexItem grow={false}>
                        <VuiCheckbox
                          checked={isCommentsWeightEnabled}
                          onChange={() => {
                            setIsCommentsWeightEnabled(
                              !isCommentsWeightEnabled
                            );
                            setCommentsWeight(
                              isCommentsWeightEnabled ? undefined : 0.1
                            );
                          }}
                          className="commentWeightCheckbox"
                        />
                      </VuiFlexItem>

                      <VuiFlexItem grow={false}>
                        {isCommentsWeightEnabled ? (
                          <VuiNumberInput
                            id="commentWeightInput"
                            className="commentWeightInput"
                            min={-0.5}
                            max={0.5}
                            step={0.01}
                            value={commentsWeight}
                            onChange={(value) => setCommentsWeight(value)}
                          />
                        ) : (
                          <VuiText>
                            <p>Enable</p>
                          </VuiText>
                        )}
                      </VuiFlexItem>
                    </VuiFlexContainer>
                  </VuiFormGroup>
                </VuiFlexItem>

                {/* Min votes */}
                <VuiFlexItem grow={false}>
                  <VuiFormGroup label="Min votes" labelFor="votesInput">
                    <VuiNumberInput
                      id="votesInput"
                      className="votesInput"
                      min={0}
                      value={votes}
                      onChange={(value) => setVotes(value)}
                    />
                  </VuiFormGroup>
                </VuiFlexItem>

                {/* Min comments */}
                <VuiFlexItem grow={false}>
                  <VuiFormGroup label="Min comments" labelFor="commentsInput">
                    <VuiNumberInput
                      id="commentsInput"
                      min={0}
                      className="commentsInput"
                      value={comments}
                      onChange={(value) => setComments(value)}
                    />
                  </VuiFormGroup>
                </VuiFlexItem>

                {/* Sub */}
                <VuiFlexItem grow={false}>
                  <VuiFormGroup label="Target sub" labelFor="targetSubInput">
                    <VuiFlexContainer
                      alignItems="center"
                      wrap={true}
                      spacing="xxs"
                    >
                      <VuiFlexItem grow={false}>
                        <VuiTextInput
                          id="targetSubInput"
                          className="targetSubInput"
                          value={targetedSub}
                          onChange={(e) => {
                            const { value } = e.target;
                            setTargetedSub(value);
                          }}
                        />
                      </VuiFlexItem>

                      {targetedSub && (
                        <VuiFlexItem grow={false}>
                          <VuiIconButton
                            color="neutral"
                            onClick={() => setTargetedSub("")}
                            icon={
                              <VuiIcon>
                                <BiX />
                              </VuiIcon>
                            }
                          />
                        </VuiFlexItem>
                      )}
                    </VuiFlexContainer>
                  </VuiFormGroup>
                </VuiFlexItem>

                {/* Diversity reranking */}
                <VuiFlexItem grow={false}>
                  <VuiFormGroup
                    label="Diversify results"
                    labelFor="diversityToggle"
                  >
                    <VuiToggle
                      className="diversityToggle"
                      id="diversityToggle"
                      checked={isDiversityReranking}
                      onChange={(e) =>
                        setIsDiversityReranking(e.target.checked)
                      }
                    />
                  </VuiFormGroup>
                </VuiFlexItem>
              </VuiFlexContainer>

              <VuiSpacer size="m" />

              {hasQuery && (
                <VuiButtonSecondary
                  color="neutral"
                  size="s"
                  onClick={() => reset()}
                >
                  Start over
                </VuiButtonSecondary>
              )}
            </fieldset>
          </VuiFlexItem>
        </VuiFlexContainer>
      </div>

      <HistoryDrawer
        isOpen={isHistoryOpen}
        onClose={() => setIsHistoryOpen(false)}
      />

      <OptionsDrawer
        isOpen={isOptionsOpen}
        onClose={() => setIsOptionsOpen(false)}
      />
    </>
  );
};
