import axios from "axios";
import { START_TAG, END_TAG } from "../utils/parseSnippet";
import { DIVERSITY_RERANKER_ID } from "../views/search/types";
import { User } from "./AuthenticationContext";

type Config = {
  filter: string;
  query?: string;
  customerId: string;
  corpusId: string;
  endpoint: string;
  apiKey: string;
  numResults: number;
  isDiversityReranking?: boolean;
  commentsWeight?: number;
  user?: User;
};

const DIVERSITY_RERANKING_CONFIG = {
  rerankingConfig: {
    rerankerId: DIVERSITY_RERANKER_ID,
    mmrConfig: {
      diversityBias: 0.1,
    },
  },
};

const RERANK_NUM_RESULTS_EXTRA = 10;

export const sendSearchRequest = async ({
  filter,
  query,
  customerId,
  corpusId,
  endpoint,
  apiKey,
  numResults,
  isDiversityReranking,
  commentsWeight,
  user,
}: Config) => {
  const corpusKeyList = corpusId.split(",").map((id) => {
    return {
      customerId,
      corpusId: id,
      lexicalInterpolationConfig: {
        lambda: 0.025,
      },
      dim:
        commentsWeight !== undefined
          ? [
              {
                name: "cnt",
                weight: commentsWeight,
              },
            ]
          : undefined,
      metadataFilter: filter,
    };
  });

  const body = {
    auth: {
      token: user?.authToken,
      email: user?.email,
    },
    query: [
      {
        query,
        start: 0,
        numResults: isDiversityReranking
          ? numResults + RERANK_NUM_RESULTS_EXTRA
          : numResults,
        corpusKey: corpusKeyList,
        contextConfig: {
          sentencesBefore: 2,
          sentencesAfter: 2,
          startTag: START_TAG,
          endTag: END_TAG,
        },
        ...(isDiversityReranking ? DIVERSITY_RERANKING_CONFIG : {}),
      },
    ],
  };

  let headers = {};
  let url = "";
  if (process.env.NODE_ENV === "production") {
    // Call proxy server if in production
    url = `/v1/query`;
    headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
  } else {
    // Call directly if in development
    url = `https://${endpoint}/v1/query`;
    headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "customer-id": customerId,
        "x-api-key": apiKey,
        "grpc-timeout": "60S",
      },
    };
  }

  const result = await axios.post(url, body, headers);

  const status = result["data"]["responseSet"][0]["status"];
  if (status.length > 0 && status[0]["code"] === "UNAUTHORIZED") {
    console.log("UNAUTHORIZED access; check your API key and customer ID");
  }

  return result["data"]["responseSet"][0];
};
