import { SearchResult } from "./SearchResult";
import { DeserializedSearchResult } from "../types";
import { useSearchContext } from "../../../contexts/SearchContext";

type Props = {
  results: Array<DeserializedSearchResult>;
  selectedSearchResultPosition?: number;
  setSearchResultRef: (el: HTMLDivElement | null, index: number) => void;
};

export const SearchResultList = ({
  results,
  selectedSearchResultPosition,
  setSearchResultRef,
}: Props) => {
  const { numResults } = useSearchContext();
  const renderableResults = results.slice(0, numResults);

  return (
    <>
      {renderableResults.map((result, i) => (
        <SearchResult
          key={i}
          result={result}
          position={i}
          isSelected={selectedSearchResultPosition === i}
          ref={(el: HTMLDivElement | null) => setSearchResultRef(el, i)}
        />
      ))}
    </>
  );
};
