import { forwardRef } from "react";
import {
  VuiText,
  VuiFlexContainer,
  VuiFlexItem,
  VuiBadge,
  VuiSearchResult,
  VuiLink,
  VuiTextColor,
  VuiButtonSecondary,
} from "../../../ui";
import { truncateEnd, truncateStart } from "../../../ui/utils/truncateString";
import { useConfigContext } from "../../../contexts/ConfigurationContext";
import { DeserializedSearchResult } from "../types";
import "./SearchResult.scss";
import { useSearchContext } from "../../../contexts/SearchContext";

type Props = {
  result: DeserializedSearchResult;
  position: number;
  isSelected: boolean;
};

const CONTEXT_MAX_LENGTH = 200;

export const SearchResult = forwardRef<HTMLDivElement | null, Props>(
  ({ result, position, isSelected }: Props, ref) => {
    const { setTargetedSub } = useSearchContext();
    const { filters } = useConfigContext();

    const {
      id,
      snippet: { pre, post, text },
    } = result;

    const { srName, srOver18, votesCount, commentsCount } = result.metadata;
    const url = `https://www.reddit.com/${id.split("_")[1]}`;
    const title = text;

    return (
      <VuiSearchResult
        ref={ref}
        isSelected={isSelected}
        result={{
          title,
          url,
          snippet: {
            pre: truncateStart(pre, CONTEXT_MAX_LENGTH),
            text,
            post: truncateEnd(post, CONTEXT_MAX_LENGTH),
          },
        }}
        position={position + 1}
        actions={
          <VuiButtonSecondary
            color="primary"
            size="s"
            onClick={() => setTargetedSub(srName as string)}
          >
            Target sub
          </VuiButtonSecondary>
        }
        subTitle={
          Boolean(filters.isEnabled || url) && (
            <VuiFlexContainer
              alignItems="center"
              spacing="xs"
              className="searchResultFilterGroup"
            >
              {srName ? (
                <VuiFlexItem grow={false}>
                  <VuiText size="s" className="searchResultSiteCategory">
                    <VuiLink
                      href={`https://www.reddit.com/r/${srName as string}/`}
                    >
                      r/{srName as string}
                    </VuiLink>
                  </VuiText>
                </VuiFlexItem>
              ) : null}

              {srOver18 === "true" && (
                <VuiFlexItem>
                  <VuiBadge color="warning">18+</VuiBadge>
                </VuiFlexItem>
              )}

              {votesCount !== undefined && (
                <VuiFlexItem>
                  <VuiBadge color="neutral">
                    {votesCount as string} votes
                  </VuiBadge>
                </VuiFlexItem>
              )}

              {commentsCount !== undefined && (
                <VuiFlexItem>
                  <VuiBadge color="neutral">
                    {commentsCount as string} comments
                  </VuiBadge>
                </VuiFlexItem>
              )}
            </VuiFlexContainer>
          )
        }
      >
        <VuiText>
          <p>
            <VuiTextColor color="subdued">ID: {id}</VuiTextColor>
          </p>
        </VuiText>
      </VuiSearchResult>
    );
  }
);
