type Config = {
  searchValue?: string;
  numResults?: number;
  votes?: number;
  comments?: number;
  targetedSub?: string;
  isDiversityReranking?: boolean;
  commentsWeight?: number;
  language?: string;
};

export const createSearchParams = ({
  searchValue = "",
  numResults,
  votes,
  comments,
  targetedSub,
  isDiversityReranking,
  commentsWeight,
  language,
}: Config) => {
  const params = [
    { name: "results", value: numResults },
    { name: "votes", value: votes },
    { name: "comments", value: comments },
    { name: "sub", value: targetedSub },
    { name: "diversity", value: isDiversityReranking },
    { name: "commentsWeight", value: commentsWeight },
    { name: "language", value: language },
  ]
    .reduce((accum, param) => {
      if (param.value !== undefined && param.value !== "") {
        accum.push(`${param.name}=${encodeURIComponent(param.value)}`);
      }
      return accum;
    }, [] as string[])
    .join("&");

  return `?query=${encodeURIComponent(searchValue)}&${params}`;
};
