import classNames from "classnames";
import { VuiSpacer } from "../spacer/Spacer";
import { VuiTextColor } from "../typography/TextColor";
import { VuiTitle } from "../typography/Title";

type Props = {
  children?: React.ReactNode;
  className?: string;
  onClick: () => void;
  title?: string;
};

export const VuiTopicButton = ({
  children,
  className,
  onClick,
  title,
  ...rest
}: Props) => {
  const classes = classNames("vuiTopicButton", className);
  return (
    <button className={classes} onClick={onClick} {...rest}>
      {title && (
        <>
          <VuiTitle size="s">
            <p>
              <VuiTextColor color="primary">{title}</VuiTextColor>
            </p>
          </VuiTitle>

          <VuiSpacer size="xxs" />
        </>
      )}

      {children}
    </button>
  );
};
