import { SummaryLanguage } from "../views/search/types";

type HistoryConfig = {
  query: string;
  results: number;
  commentsWeight?: number;
  votes?: number;
  comments?: number;
  sub?: string;
  isDiversity?: boolean;
  language: SummaryLanguage;
};

export type HistoryItem = HistoryConfig & {
  date: string;
};

// eslint-disable-next-line
const HISTORY_KEY = `${location.hostname}:searchHistory`;

export const addHistoryItem = (
  {
    query,
    results,
    commentsWeight,
    votes,
    comments,
    sub,
    isDiversity,
    language,
  }: HistoryConfig,
  history: HistoryItem[]
) => {
  const date = new Intl.DateTimeFormat("en", {
    dateStyle: "medium",
    timeStyle: "medium",
  }).format(new Date());

  const newHistoryItem = {
    query,
    commentsWeight,
    results,
    votes,
    comments,
    sub,
    isDiversity,
    language,
    date,
  };
  const newHistory = [newHistoryItem, ...history];
  persistHistory(newHistory);
  return newHistory;
};

export const deleteHistory = () => {
  localStorage.removeItem(HISTORY_KEY);
};

export const retrieveHistory = () => {
  const serializedHistory = localStorage.getItem(HISTORY_KEY);
  if (!serializedHistory) return [];
  const entry = JSON.parse(serializedHistory);
  return entry;
};

export const persistHistory = (history: HistoryItem[]) => {
  const serializedHistory = JSON.stringify(history);
  localStorage.setItem(HISTORY_KEY, serializedHistory);
};
