import {
  VuiButtonPrimary,
  VuiCallout,
  VuiFlexContainer,
  VuiFlexItem,
  VuiSpacer,
  VuiSpinner,
  VuiTitle,
} from "../../ui";
import { SearchControls } from "./controls/SearchControls";
import { ExampleQuestions } from "./controls/ExampleQuestions";
import { useSearchContext } from "../../contexts/SearchContext";
import { AppHeader } from "./chrome/AppHeader";
import { AppFooter } from "./chrome/AppFooter";
import { useConfigContext } from "../../contexts/ConfigurationContext";
import { SearchUx } from "./SearchUx";
import { SummaryUx } from "./SummaryUx";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";
import "./searchView.scss";

const uxModeToComponentMap = {
  search: <SearchUx />,
  summary: <SummaryUx />,
} as const;

export const SearchView = () => {
  const { logOut } = useAuthenticationContext();
  const { isConfigLoaded, app, uxMode } = useConfigContext();
  const { isSearching, searchError, searchResults } = useSearchContext();

  let content;

  if (!isConfigLoaded) {
    content = (
      <VuiFlexContainer
        className="appSpinner"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <VuiSpinner size="l" />
        <VuiSpacer size="l" />
        <VuiTitle size="xs">
          <h2>Loading</h2>
        </VuiTitle>
      </VuiFlexContainer>
    );
  } else if (!isSearching && !searchError && !searchResults) {
    content = <ExampleQuestions />;
  } else {
    content = uxModeToComponentMap[uxMode];
  }

  let errorCallout;

  if (searchError) {
    const isReauth = searchError?.response?.status === 401;
    const title = searchError.response?.data ?? "There was an error";
    errorCallout = (
      <VuiCallout color="danger" title={title} headingElement="h2">
        {isReauth && (
          <VuiButtonPrimary color="primary" onClick={logOut}>
            Log in again
          </VuiButtonPrimary>
        )}
      </VuiCallout>
    );
  }

  return (
    <>
      {app.isHeaderEnabled && <AppHeader />}
      <VuiFlexContainer
        className="searchView"
        direction="column"
        alignItems="center"
        spacing="none"
      >
        {isConfigLoaded && (
          <VuiFlexItem className="searchControlsContainer">
            {errorCallout}
            <SearchControls hasQuery={Boolean(isSearching || searchResults)} />
          </VuiFlexItem>
        )}

        <VuiFlexItem grow={1} className="searchContent">
          {content}
        </VuiFlexItem>

        {app.isFooterEnabled && <AppFooter />}
      </VuiFlexContainer>
    </>
  );
};
