import { createContext, ReactNode, useContext, useState } from "react";
import { googleLogout, GoogleOAuthProvider } from "@react-oauth/google";
import { useConfigContext } from "./ConfigurationContext";

const domainAllowList = ["vectara.com"];
const userAllowList: string[] = [
  "pali@reddit.com",
  "serkan@reddit.com",
  "douglas.turnbull@reddit.com",
  "jonathan.gifford@reddit.com",
  "jesjit.birak@reddit.com",
  "paul.raff@reddit.com",
  "preetom.chakraborty@reddit.com",
  "courtney.wang@reddit.com",
  "chris.schomaker@reddit.com",
  "mdoherty@reddit.com",
  "cliff.chen@reddit.com",
  "jared.martin@reddit.com",
  "joel.westberg@reddit.com",
  "matt.cristantello@reddit.com",
  "michael.jones@reddit.com",
  "sahand.akbari@reddit.com",
  "yao.xiao@reddit.com",
  "mike.wright@reddit.com",
  "alex.corcoran@reddit.com",
  "chris.lehew@reddit.com",
  "corwin.vanhook@reddit.com",
  "manisha.patel@reddit.com",
  "natalie.djerf@reddit.com",
  "tadhg.creedon@reddit.com",
  "tanzeela.khan@reddit.com",
  "terence.weber@reddit.com",
];

export type User = { email: string; authToken: string };

interface AuthenticationContextType {
  isAuthEnabled: boolean;
  user?: User;
  isAuthenticated: boolean;
  logIn: (authToken: string | undefined | null) => void;
  logOut: () => void;
}

const AuthenticationContext = createContext<
  AuthenticationContextType | undefined
>(undefined);

type Props = {
  children: ReactNode;
};

const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const AuthenticationContextProvider = ({ children }: Props) => {
  const { auth } = useConfigContext();
  const [user, setUser] = useState<User>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const logIn = (authToken: string | undefined | null) => {
    if (!authToken) {
      return;
    }

    const userInfo = parseJwt(authToken);

    const domain = userInfo.email.split("@")[1].toLowerCase();
    if (
      !domainAllowList.includes(domain) &&
      !userAllowList.includes(userInfo.email)
    ) {
      throw Error(
        `The user ${userInfo.email} is not authorized to use this application.`
      );
    }

    const user = {
      authToken,
      email: userInfo.email,
    };

    setUser(user);
    setIsAuthenticated(true);
    localStorage.setItem("AuthToken", authToken);
  };

  const logOut = () => {
    googleLogout();
    setUser(undefined);
    setIsAuthenticated(false);
    localStorage.removeItem("AuthToken");
  };

  const isAuthEnabled = auth.isEnabled;

  if (isAuthEnabled && !auth.googleClientId) {
    throw Error(
      "There's an error in the config file. 'google_client_id' must be set to a Google API client ID when 'authenticate' is set to True. See https://developers.google.com/identity/oauth2/web/guides/get-google-api-clientid."
    );
  }

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthEnabled,
        user,
        isAuthenticated: isAuthEnabled ? isAuthenticated : true,
        logIn,
        logOut,
      }}
    >
      <GoogleOAuthProvider clientId={auth.googleClientId ?? ""}>
        {children}
      </GoogleOAuthProvider>
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthenticationContext must be used within a AuthenticationContextProvider"
    );
  }

  return context;
};
